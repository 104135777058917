// src/components/Navbar.js
import React, { useEffect, useState } from 'react';
import Hamburger from 'hamburger-react';
import './Navbar.css';
import logo from '../../assets/images/logo.png';
import { motion } from 'motion/react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Navbar = () => {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false);
    const [navList, setNavList] = useState([
        { name: 'Home', link: '/' },
        { name: 'About Us', link: '#about' },
        { name: 'Gallery', link: '#gallery' },
        { name: 'Videos', link: '#videos' },
        { name: 'Reviews', link: '#reviews' },
        { name: 'Events', link: '/events' },
        { name: 'Blogs', link: '/blogs' },
        { name: 'Contact Us', link: '/contact' },
    ])

    useEffect(() => {
        if (location?.pathname === '/enquire') {
            setNavList([
                { name: 'Home', link: '/' },

                { name: 'Locations', link: '#locations' },
                { name: 'Events', link: '/events' },
                { name: 'Blogs', link: '/blogs' },
                { name: 'Contact Us', link: '/contact' },
            ])
        }
        else if (location?.pathname.includes('events') || location?.pathname.includes('blogs') || location?.pathname.includes('contact')) {
            setNavList([
                { name: 'Home', link: '/' },
                { name: 'Events', link: '/events' },
                { name: 'Blogs', link: '/blogs' },
                { name: 'Contact Us', link: '/contact' }
            ])
        }
    }, [location?.pathname])
    return (
        <nav className="navbar">
            <div className="logo">
                <Link to="/"><img src={logo} alt="MJ Dance Class Logo" /></Link>
            </div>

            <div className="hamburger-menu">
                <Hamburger toggled={isOpen} toggle={setIsOpen} />
            </div>

            <ul className={`nav-list ${isOpen ? 'show' : ''}`}>
                {navList.map((item, index) => (
                    <motion.li
                        key={index}
                        whileTap={{ scale: 0.9 }}
                        className="nav-item"
                    >
                        {item.link.includes("/") ? <NavLink className={({ isActive }) =>
                            `${isActive ? "active" : ""
                            }`
                        } to={item.link} >{item.name}</NavLink> : <AnchorLink href={item.link} offset="100">{item.name}</AnchorLink>}
                    </motion.li>
                ))}
            </ul>


            <Link to="/enquire" className={`enquire-now ${isOpen ? 'show' : ''}`}>
                Enquire Now
            </Link>
        </nav>
    );
};

export default Navbar;
