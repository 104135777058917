import React from 'react'
import "./GalleryCard.css"
const GalleryCard = ({ img }) => {
    return (
        <div className='gallery-card'>
            <img src={img} alt='Bombay Glam Dance Academy' />
        </div>
    )
}

export default GalleryCard
