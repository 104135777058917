import React from 'react'
import './SpecialityCard.css'
import { motion } from 'motion/react'
import { Link } from 'react-router-dom'
const SpecialityCard = ({ img, title, description }) => {
    return (
        <motion.div className='s-card'
            initial={{ scale: 0.7 }}
            whileInView={{ scale: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}
        >
            <img src={img} alt={title} />
            <h3>{title}</h3>
            <p>{description}</p>
            <Link to='/enquire'>Enquire Now</Link>
        </motion.div>
    )
}

export default SpecialityCard
