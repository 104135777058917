import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Contact from '../../components/contact/Contact'
import { Helmet } from 'react-helmet'
const ContactPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Bombay Glam Dance Academy</title>
                <link rel="canonical" href="https://bombayglamdanceacademy.com/contact" />
                <meta name="description" content="Get in touch with Bombay Glam Dance Academy for inquiries, collaborations, or bookings. We're here to bring glam to your dance journey!" />
            </Helmet>
            <Navbar />
            <Contact />
            <Footer />
        </>
    )
}

export default ContactPage
