// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './pages/home/HomePage';
import Whatsapp from './components/whatsapp/Whatsapp';
import { Toaster } from 'react-hot-toast';
import Enquire from './pages/enquire/Enquire';
import BlogsPage from './pages/blogs/BlogsPage';
import SingleBlog from './pages/singleblog/SingleBlog';
import Events from './pages/events/Events';
import ContactPage from './pages/contact/Contact';

function App() {
  return (
    <div className='app'>
      <Toaster />
      <Whatsapp />
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/enquire" element={<Enquire />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:id/:title" element={<SingleBlog />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
