import React from 'react'
import './Contact.css'
import { motion } from 'motion/react'
import { ClockIcon, EnvelopeOpenIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import emailjs from "@emailjs/browser";
import { db } from "../../firebase/firebase";

import {
    collection,
    addDoc,
} from "firebase/firestore";

const Contact = () => {
    const formRef = useRef()
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)
    const handleChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const saveDetails = async (success) => {
        if (!form.name || !form.email || !form.message || !form.phone) {
            toast('Please fill the contact form!', {
                icon: '⚠️', // Warning icon
                style: {
                    border: '1px solid #FFA500',
                    padding: '10px',
                },
            });
        }

        try {

            await addDoc(collection(db, "contactDetails"), {
                name: form?.name,// HTML content from Quill
                email: form?.email,
                message: form?.message,
                phone: form?.phone,
                createdAt: new Date(),
            });
            if (!success) toast.success("Message sent successfully!")
        } catch (err) {
            console.error("Something went wrong", err);
            toast.error("Something went wrong")
        }
    }
    const sendEmail = (e) => {
        e.preventDefault()
        if (!form.name || !form.email || !form.message || !form.phone) {
            toast('Please fill the contact form!', {
                icon: '⚠️', // Warning icon
                style: {
                    border: '1px solid #FFA500',
                    padding: '10px',
                },
            });
        } else {
            setLoading(true);
            emailjs
                .sendForm(
                    process.env.REACT_APP_SERVICE_KEY,
                    process.env.REACT_APP_TEMPLATE_KEY,
                    formRef.current,
                    process.env.REACT_APP_PUBLIC_KEY
                )
                .then(
                    (result) => {
                        saveDetails(true)
                        setLoading(false);
                        toast.success("Message sent successfully!")
                    },
                    (error) => {
                        console.error(error);
                        saveDetails(false)
                        setLoading(false);
                        // toast.error("Something went wrong!")
                    }
                ).finally(() => {
                    setLoading(false)
                });
        }

    };
    return (
        <div className='contact' id="contact">
            <h3>Contact Us</h3>
            <div className='details-form'>
                <motion.div className='details' initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 1 } }}
                    viewport={{ once: true }}>
                    <div className='detail'>
                        <MapPinIcon className='size-10 text-red-500' />
                        <div>
                            <h3>Our Office Address</h3>
                            <p className='map'>1. <a href='https://maps.app.goo.gl/DbN7DnedgQJzU1n98'>Bombay Glam Dance & Fitness Studio
                                New Amity University, Gate, 3, Ishanika estate, Lucknow, Uttar Pradesh 226010</a></p>
                            <p className='map'>2. <a href='https://maps.app.goo.gl/7tZqyL1vbiB8kcd9A'>Bombay Glam Dance & Fitness Academy
                                Front of, Gyan Bhawan, Kapoorthla, Sector A, Aliganj, Lucknow, Uttar Pradesh 226024</a>
                            </p>
                        </div>
                    </div>
                    <div className='detail'>
                        <EnvelopeOpenIcon className="size-6 text-red-500" />
                        <div>
                            <h3>Email</h3>
                            <p className='map'><a href='mailto:bombayglamdanceacademy06@gmail.com'>bombayglamdanceacademy06@gmail.com </a></p>
                        </div>
                    </div>
                    <div className='detail'>
                        <PhoneIcon className="size-6 text-red-500" />
                        <div>
                            <h3>Call Us</h3>
                            <p className='map'><a href='tel:9555506196'>+91 9555506196</a></p>
                        </div>
                    </div>
                    <div className='detail'>
                        <ClockIcon className="size-6 text-red-500" />
                        <div>
                            <h3>Our Timing (Monday to Friday)</h3>
                            <p>Morning: 7:00 AM - 10:00 AM</p>
                            <p>Evening: 3:00 PM - 8:00 PM</p>
                        </div>
                    </div>

                </motion.div>
                <motion.form ref={formRef} className='form' initial={{ x: 100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 1 } }}
                    viewport={{ once: true }}>
                    <input type='text' placeholder='Name' name='name' value={form.name} onChange={handleChange} />
                    <input type='email' placeholder='Email' name='email' value={form.email} onChange={handleChange} />
                    <input type='text' placeholder='Phone No.' name='phone' value={form.phone} onChange={handleChange} />
                    <textarea type='text' placeholder='Message' name="message" value={form.message} onChange={handleChange} />
                    <motion.button disabled={loading} whileHover={{ scale: 1.1 }} onClick={sendEmail} >{loading ? "Sending..." : "Submit"}</motion.button>

                </motion.form>
            </div>

        </div>
    )
}

export default Contact
