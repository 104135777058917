import React from 'react'
import './Enquire.css'
import Navbar from '../../components/navbar/Navbar'
import { motion } from 'motion/react'
import GalleryCard from '../../components/gallery-card/GalleryCard'
import Carousel from 'react-multi-carousel'
import Contact from '../../components/contact/Contact'
import AddressMap from '../../components/address-map/AddressMap'
import Footer from '../../components/footer/Footer'
import { Helmet } from 'react-helmet'
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/achievements', false, /\.(png|jpe?g|svg)$/));
const Enquire = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Achievements | Contact Us | Locations | Bombay Glam Dance Academy</title>
                <link rel="canonical" href="https://bombayglamdanceacademy.com/enquire" />
                <meta name="description" content="Have questions about classes or events? Enquire with Bombay Glam Dance Academy and start your journey into the world of dance today!" />
            </Helmet>
            <Navbar />
            {/* <Heading /> */}
            <div className='temp' style={{ backgroundColor: 'black' }}>
                <motion.div className='gallery'
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 1 } }}
                    viewport={{ once: true }}
                >
                    <h3 id="gallery">Achievements</h3>
                    <Carousel responsive={responsive} infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        customTransition="all 0.5s"
                        transitionDuration={500}>
                        {images.map((image) => (
                            <GalleryCard img={image} />
                        ))}
                    </Carousel>
                </motion.div>
            </div>
            <Contact />
            <AddressMap />
            <Footer />
        </>
    )
}

export default Enquire
