import React, { useState, useEffect } from 'react'
import "./Events.css"
import EventCard from '../../components/event-card/EventCard'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import {
    collection,
    onSnapshot,

} from "firebase/firestore";
import { db } from '../../firebase/firebase'
import Loader from '../../components/loader/Loader'
import Helmet from 'react-helmet'
import DsiplayMessage from '../../components/display-message/DsiplayMessage'
const Events = () => {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState({ error: false, message: {} })
    useEffect(() => {
        setLoading(true)
        const unsubscribe = onSnapshot(collection(db, "events"), (snapshot) => {
            const eventsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setEvents(eventsData);
            setLoading(false)
        }, (error) => {
            console.error("Error fetching document:", error)
            setLoading(false)
            setHasError({ error: true, message: { title: "Something went wrong", description: "Please try again later!" } })
        });

        return () => unsubscribe();
    }, []);
    console.log(events)
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Upcomig Events at Bombay Glam Dance Academy</title>
                <link rel="canonical" href="https://bombayglamdanceacademy.com/events" />
                <meta name="description"
                    content="Discover dazzling dance events and performances by Bombay Glam Dance Academy. Stay updated with our upcoming showcases and workshops. Join the rhythm today!" />
            </Helmet>
            <Navbar />
            <section class="events-section">
                <h2>Upcoming Events</h2>
                {/* {events.length > 0 ? events.map((event, index) => (
                    <EventCard key={event?.id} event={event} reverse={index % 2 !== 0} />
                )) : <Loader />} */}
                {loading ? <Loader /> : events?.length > 0 ? events.map((event, index) => (
                    <EventCard key={event?.id} event={event} reverse={index % 2 !== 0} />
                )) : !hasError?.error ? <DsiplayMessage title="No Events" description="Check back later for updates or explore our other sections!" /> : <DsiplayMessage title={hasError?.message?.title} description={hasError?.message?.description} />}
            </section>
            <Footer />
        </>
    )
}

export default Events
