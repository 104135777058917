import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Heading from '../../components/heading/Heading'
import AboutUs from '../../assets/images/about-us.jpg'
import bollywood from "../../assets/images/bollywood.jpg"
import contemporary from '../../assets/images/contemporary.jpg'
import hiphop from '../../assets/images/hip-hop.jpg'
import yoga from "../../assets/images/yoga.jpg"
import "./HomePage.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SpecialityCard from '../../components/speciality-card/SpecialityCard'
import GalleryCard from '../../components/gallery-card/GalleryCard'
import TestimonialCarousel from '../../components/tetimonial-carousel/TestimonialCarousel'
import { motion, useScroll } from 'motion/react'
import Footer from '../../components/footer/Footer'
import { Helmet } from 'react-helmet'

import Contact from '../../components/contact/Contact'
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

// const responsiveReview = {
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 1
//     }
// };

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/gallery', false, /\.(png|jpe?g|svg)$/));

const HomePage = () => {
    const { scrollYProgress } = useScroll();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bombay Glam Dance Academy | Dance | Yoga</title>
                <link rel="canonical" href="https://bombayglamdanceacademy.com/" />
                <meta name="description"
                    content="Join the best dance academy for Bollywood and Yoga classes at Bombay Glam Dance Academy. Best Dance Academy in Lucknow" />
            </Helmet>
            <motion.div className='progress-bar' style={{ scaleX: scrollYProgress, width: '100%' }} />
            <Navbar />
            <Heading />
            <div className='speciality'>
                <h3>Our Speciality</h3>
                <div className='speciality-cards'>
                    <SpecialityCard img={bollywood} title="Bolloywood" description="Feel the rhythm, embrace the colors, and let Bollywood dance take you to a world of joy and expression!" />
                    <SpecialityCard img={contemporary} title="Contemporary" description="Freedom to explore, to move beyond tradition, and to express emotion in its purest, most unrestrained form." />
                    <SpecialityCard img={hiphop} title="Hip Hop" description="Dance to your own beat; let the streets guide your flow and the music fuel your passion in every hip-hop move." />
                    <SpecialityCard img={yoga} title="Yoga" description="With Yoga find balance in your breath, strength in stillness, and peace within every pose." />
                </div>
            </div>
            <div className='about-section' id="about">
                <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 0.8 } }}
                    viewport={{ once: true }}
                    className='about-img'>
                    <img src={AboutUs} alt="Bombay Glam Dance Academy" />
                </motion.div>
                <motion.div className='about-us'
                    initial={{ x: 100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 0.8 } }}
                    viewport={{ once: true }}>
                    <div>
                        <h3>About Us</h3>

                        {/* <p>At BGDA, dance is more than movement; it’s a powerful expression of the soul, a confidence builder, and a journey to discover one’s unique rhythm. We offer a range of classes for all ages and skill levels, including Contemporary, Bollywood, Hip-Hop, and Yoga for those looking to strengthen both body and mind. With experienced and dedicated instructors, we foster an environment where students can explore diverse dance styles, enhance flexibility, and build strength.</p> */}
                        <p>Bombay Glam Dance and Fitness Academy, established in 2015 by Mr. Prabhat Mishra (a protégé of Terence Lewis), is a dynamic hub for dance and fitness enthusiasts.</p>

                        <p>The academy boasts a skilled team of instructors who train students of all age groups in a diverse range of dance styles, including Bollywood, B-Boying, Hip Hop, Locking Popping, Zumba, Salsa, Contemporary, and Hoola Hoop.</p>

                        <p> Offering special online batches, the studio also provides services like stage shows and professional choreography for various events.</p>

                        <p>In addition to dance, the academy focuses on fitness, offering strength and flexibility training to help individuals achieve their wellness goals.</p>

                    </div>
                </motion.div>
            </div>

            <div className='gallery-videos'>
                <motion.div className='gallery'
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 1 } }}
                    viewport={{ once: true }}
                >
                    <h3 id="gallery">Gallery</h3>
                    <Carousel responsive={responsive} infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        customTransition="all 0.5s"
                        transitionDuration={500}>
                        {images.map((image, index) => (
                            <GalleryCard img={image} />
                        ))}
                    </Carousel>
                </motion.div>
                <motion.div className='videos' initial={{ x: 100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1, transition: { delay: 1 } }}
                    viewport={{ once: true }}>
                    <h3 id="videos">Videos</h3>
                    <div>
                        {/* <iframe src='https://www.youtube.com/embed/84MVTFn42cI' title="Bombay Glam Dance Academy" style={{ border: '5px solid red' }} width="550" height="300" >
                        </iframe> */}
                        <iframe src='https://www.youtube.com/embed/_s_8lK9e7mw' title="Bombay Glam Dance Academy" style={{ border: '5px solid red' }} width="550" height="300">
                        </iframe>
                    </div>
                </motion.div>
            </div>

            <TestimonialCarousel />

            <Contact />
            <Footer />

        </>
    )
}

export default HomePage
