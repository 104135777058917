import React, { useState, useEffect } from 'react'
import "./SingleBlog.css"
import { Link, useParams } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { Helmet } from 'react-helmet'
import { onSnapshot, doc } from "firebase/firestore";
import { db } from '../../firebase/firebase'
import Loader from '../../components/loader/Loader'
import { motion, useScroll } from 'motion/react'
import { convertTimestampToDate } from '../../helpers/helpers'
import DsiplayMessage from '../../components/display-message/DsiplayMessage'
const SingleBlog = () => {
    const params = useParams()
    const { scrollYProgress } = useScroll();

    const [blog, setBlog] = useState(null)
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState({ error: false, message: { title: "", description: '' } })



    useEffect(() => {
        const fetchBlogById = (id) => {
            if (!id) return;
            setLoading(true)
            const blogDocRef = doc(db, "blogs", id);

            const unsubscribe = onSnapshot(blogDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const blogData = { id: docSnapshot.id, ...docSnapshot.data() };
                    setBlog(blogData);
                } else {
                    console.error("No such document!");
                    setBlog(null);
                    setHasError({ error: true, message: { title: "No Such Blog", description: "This blog doesn't exist!" } })
                }
                setLoading(false);
            }, (error) => {
                console.error("Error fetching document:", error)
                setLoading(false);
                setHasError({ error: true, message: { title: "Something went wrong", description: "Please try again later!" } })
            });

            return () => unsubscribe();
        };

        fetchBlogById(params.id);
    }, [params?.id]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{params?.title}</title>
                <link rel="canonical" href={"https://bombayglamdanceacademy.com/blogs/" + params?.title} />
            </Helmet>
            <motion.div className='progress-bar' style={{ scaleX: scrollYProgress, width: '100%' }} />
            <Navbar />
            {<div class="singleblog-container">
                {blog ? <div>
                    <h2 class="singleblog-title">{blog?.title}</h2>
                    <p class="singleblog-meta">Published on {blog && convertTimestampToDate(blog?.createdAt)} | By Admin</p>
                    <div class="singleblog-content" dangerouslySetInnerHTML={{
                        __html: blog?.content
                    }} />
                    <Link to="/blogs" class="back-button">Back to Blog</Link>
                </div> : loading ? <Loader /> : <DsiplayMessage title={hasError?.message?.title} description={hasError?.message?.description} />}
            </div>}
            <Footer />
        </>

    )
}

export default SingleBlog
