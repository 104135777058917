import React from 'react'
import "./EventCard.css"
import { Link } from 'react-router-dom'
import { formatDate, formatTime24to12 } from '../../helpers/helpers'
const EventCard = ({ reverse, event }) => {
    console.log(event)
    return (
        <div class={reverse ? "event-card reverse" : "event-card"}>
            <img src={event?.imageUrl || "https://via.placeholder.com/400x200"} alt="Event 1" class="event-image" />
            <div class="event-content">
                <h3 class="event-title">{event?.title}</h3>
                <p class="event-date">{event && formatDate(event.eventDate)},{event && formatTime24to12(event.eventTime)}</p>
                <p class="event-description">{event.description}</p>
                <Link to="/contact" class="event-button">Contact Us</Link>
            </div>
        </div>
    )
}

export default EventCard
