export function extractFirstImageSrc(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const firstImg = doc.querySelector("img");

    return firstImg ? firstImg.src : null;
}

export function formatDate(isoDate) {
    const date = new Date(isoDate);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
}

export function formatTime24to12(time24) {
    const [hour, minute] = time24.split(':').map(Number);


    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
}

export const convertTimestampToDate = (timestamp) => {
    const { seconds, nanoseconds } = timestamp;

    const milliseconds = seconds * 1000 + nanoseconds / 1000000;

    const date = new Date(milliseconds);

    const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    });

    // const formattedTime = date.toLocaleTimeString("en-US", {
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //     hour12: true
    // });

    return `${formattedDate}`;
}