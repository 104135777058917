import React from 'react'
import './AddressMap.css'
const AddressMap = () => {
    return (
        <div className='addressMap' id="locations">
            <h3>Locations</h3>
            <div className="gmaps">
                <div className='gmap'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14238.658166847572!2d81.0390312!3d26.85062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be3b8136fcfab%3A0x7fd20ec3c51fe7d4!2sBombay%20Glam%20Dance%20%26%20Fitness%20Studio!5e0!3m2!1sen!2sin!4v1732186372182!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        title='Bombay Glam Dance & Fitness Studio New Amity University, Gate, 3, Ishanika estate, Lucknow, Uttar Pradesh 226010'
                        frameborder="0"
                        style={{ border: 0, borderRadius: '10px' }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    />
                    <h3>Bombay Glam Dance & Fitness Studio New Amity University, Gate, 3, Ishanika estate, Lucknow, Uttar Pradesh 226010</h3>

                </div>
                <div className='gmap'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.686597895074!2d80.9438203!3d26.881696599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3999578aaa68dedd%3A0xd83e39ebabcec288!2sBombay%20Glam%20Dance%20%26%20Fitness%20Academy!5e0!3m2!1sen!2sin!4v1732186547810!5m2!1sen!2sin"
                        width="100%"
                        title='Bombay Glam Dance & Fitness Academy Front of, Gyan Bhawan, Kapoorthla, Sector A, Aliganj, Lucknow, Uttar Pradesh 226024'
                        height="450"
                        frameborder="0"
                        style={{ border: 0, borderRadius: '10px' }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    />
                    <h3>Bombay Glam Dance & Fitness Academy Front of, Gyan Bhawan, Kapoorthla, Sector A, Aliganj, Lucknow, Uttar Pradesh 226024</h3>

                </div>
            </div>
        </div>
    );
}

export default AddressMap
