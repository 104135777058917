import React from 'react'
import background from "../../assets/images/background.jpg"
import { motion } from 'motion/react';
import './Heading.css'
import { Link } from 'react-router-dom';
const Heading = () => {

    return (
        <div className="heading relative flex justify-center items-center h-screen bg-black text-white">
            <img src={background} alt="Dancers" className="absolute inset-0 w-full h-full object-cover opacity-50" />
            <motion.div className="relative z-10 text-left"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1, transition: { duration: 1, type: 'spring' } }}
            >
                <h1 className="text-4xl md:text-6xl font-bold mb-6">Step into Excellence, Grace, and Rhythm</h1>
                <p className='text-3xl mb-8' style={{ color: 'grey' }}>Contemporary | Hip Hop | Cardio | Yoga | Strength Training</p>
                <Link to='/enquire' className="px-6 py-3 text-lg font-semibold rounded-lg" style={{ backgroundColor: 'red' }}>Enquire Now</Link>
            </motion.div>
        </div>
    )
}

export default Heading
