import React from 'react'
import { motion } from 'motion/react'
import './Whatsapp.css'
const Whatsapp = () => {
    return (
        <>
            <motion.a
                whileHover={{ scale: 1.1 }}
                href={`https://wa.me/${919555506196}`}
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </motion.a>
        </>
    )
}

export default Whatsapp
