import React, { useState, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "./BlogsPage.css"
import BlogCard from '../../components/blog-card/BlogCard'
import { onSnapshot, collection } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import Loader from '../../components/loader/Loader'
import DsiplayMessage from '../../components/display-message/DsiplayMessage'
import { Helmet } from 'react-helmet'
const BlogsPage = () => {
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState({ error: false, message: {} })
    useEffect(() => {
        const fetchBlogs = () => {
            setLoading(true)
            const unsubscribe = onSnapshot(collection(db, "blogs"), (snapshot) => {
                const blogsData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setBlogs(blogsData);
                setLoading(false)

            }, (error) => {
                console.error("Error fetching document:", error)
                setLoading(false)
                setHasError({ error: true, message: { title: "Something went wrong", description: "Please try again later!" } })
            });


            return () => unsubscribe();
        }
        fetchBlogs()
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blogs | Bombay Glam Dance Academy | Best Dance Academy in Lucknow</title>
                <link rel="canonical" href="https://bombayglamdanceacademy.com/blogs" />
                <meta name="description" content="Explore insightful blogs on dance trends, tips, and stories at Bombay Glam Dance Academy. Dive into the world of movement and inspiration." />
            </Helmet>
            <Navbar />
            <div class="blog-container">
                {loading ? <Loader /> : blogs?.length > 0 ? blogs.map((blog) => <BlogCard key={blog.id} blog={blog} />) : !hasError?.error ? <DsiplayMessage title="No Blogs to Show" description="Check back later for updates or explore our other sections!" /> : <DsiplayMessage title={hasError?.message?.title} description={hasError?.message?.description} />}
            </div>
            <Footer />
        </>
    )
}

export default BlogsPage
