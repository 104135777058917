import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./TestimonialCarousel.css";

const testimonials = [
    {
        text: "They have experienced dancers around to take proper care of all the students from the beginning.",
        author: "Sushant Rajput",
        image: "https://via.placeholder.com/50" // Replace with actual image URLs
    },
    {
        text: "The instructors are friendly and knowledgeable, creating a great learning environment.",
        author: "Priya Sharma",
        image: "https://via.placeholder.com/50"
    },
    {
        text: "Amazing place to learn dance and grow in a supportive community.",
        author: "Rahul Verma",
        image: "https://via.placeholder.com/50"
    }
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const TestimonialCarousel = () => {
    return (
        <div className="testimonial-section">
            <h2 id="reviews">Reviews</h2>
            <Carousel
                responsive={responsive}
                showDots={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                customTransition="all 0.5s"
                transitionDuration={500}
            >
                {testimonials.map((testimonial, index) => (
                    <div className="testimonial" key={index}>
                        <span className="quote-left">“</span>
                        <p>{testimonial.text}</p>
                        <span className="quote-right">”</span>
                        <div className="author">
                            <img src={testimonial.image} alt={testimonial.author} />
                            <span>{testimonial.author}</span>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default TestimonialCarousel;
