import React from 'react'
import "./BlogCard.css"
import { Link } from 'react-router-dom'
import { extractFirstImageSrc } from '../../helpers/helpers'
const BlogCard = ({ blog }) => {
    return (
        <div class="blog-post">
            <img src={extractFirstImageSrc(blog.content) || "https://via.placeholder.com/1200x600"} alt="Blog Post 1" />
            <div class="blog-post-content">
                <h2 class="blog-post-title">{blog.title}</h2>
                <p class="blog-post-text">{blog.description.slice(0, 100) + '...'}</p>
                <Link to={"/blogs/" + blog.id + "/" + blog.title} class="read-more">Read More</Link>
            </div>
        </div>
    )
}

export default BlogCard
