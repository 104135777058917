import React from 'react'

const DsiplayMessage = ({ title, description }) => {
    return (
        <div className="flex flex-col justify-center items-center bg-white">
            <div className="text-center border border-gray-300 rounded-lg p-6 bg-gray-100 shadow-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
                <p className="text-gray-600 mt-2">{description}</p>
            </div>
        </div>
    )
}

export default DsiplayMessage
